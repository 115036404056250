/* UserNameWebShow.css */

.episode-container {
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
}

.episode-container:hover {
  background-color: #007BFF; /* This should match your primary color */
}

.episode-container:hover .episode-title,
.episode-container:hover .episode-description {
  color: white;
}