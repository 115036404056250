/* src/animations.css */

@keyframes dropLetter {
  0% {
    transform: translateY(-50vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.letter-animation {
  display: inline-block;
  opacity: 0;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.content-animation {
  animation: fadeIn 1s ease-in forwards;
}

.logo-fade-out {
  animation: fadeOut 2s ease-out forwards;
}